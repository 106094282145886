import React, { lazy, Suspense } from 'react';
import Navbar from 'components/navbar';
import Footer from 'components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'scss/abstract.scss';
import 'scss/gsap.scss';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// Cargar el CookieBanner de manera diferida
const CookieBanner = lazy(() => import('components/cookie-banner'));

class Layout extends React.Component {

    componentDidMount() {
        // Inicializa ScrollTrigger
        gsap.registerPlugin(ScrollTrigger);
    
        // Configura ScrollTrigger
        ScrollTrigger.create({
          trigger: '#main', // Cambia esto al selector de tu contenedor principal
          start: 'top top',
          end: '+=500%', // Ajusta esto según el tamaño de tu contenido
          scrub: 1,
          snap: 1,
        });
    }

    render() {
        const { children } = this.props    

        return (
            <div id="main">
                <Navbar scroll={this.props.isHome ? true : false} sections={this.props.sections} />        
                <div className="scroll-container">
                    {children}
                </div>
                <Footer /> 
                {/* Cargar el CookieBanner de manera diferida */}
                <Suspense fallback={<div>Loading...</div>}>
                    <CookieBanner />
                </Suspense>  
            </div>
        );
    }
}

export default Layout;
